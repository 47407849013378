/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

import { events } from "variables/general.js";

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      alert: null,
    };
  }
  selectedEvent = (event) => {
    alert(event.title);
  };
  addNewEventAlert = (slotInfo) => {
  };
  addNewEvent = (e, slotInfo) => {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    this.setState({
      alert: null,
      events: newEvents,
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h2>Terms of Use</h2>
              <a href="https://drive.google.com/file/d/17ik2T0CwEfRayRSf_9D2JExzqp97XeL_/view" target="_blank" rel="noreferrer"><h3>PDF</h3></a>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Terms;
